






























































































































































































































































































import Vue from 'vue'
import { FormRef, Rule, SelectOption } from '@/models/form'
import { mapState } from 'vuex'
import { ORDER_REQUIREMENTS, CONDITIONAL_OPTIONS } from './constant'
import { DerivativeInfoType } from '../DerivativeInfos/types'
import { OrderReq, SIDE_ORDER } from '@/services/order/models'
import CrossMarginFeatures from './components/CrossMarginFeatures.vue'
import StepInput from '@/components/StepInput.vue'

enum FORM_PURPOSE {
  MARKET = 'market',
  LIMIT = 'limit'
}

enum TRIGGER_TYPES {
  RATE = 'rate',
  PRICE = 'price'
}

export type DataEmitOrder = OrderReq & {
  market: string
  baseCurrency: string
  orderPrice: number
  isMarketPurpose: boolean
}

interface Param {
  conditionalType: number
  triggerPrice: number
  unitPrice: number
  totalPrice: number
  orderPrice: number
  bookRate: number
  triggerTypeRate: number
  triggerTypePrice: number
  timeout: number
  requirements: number[]
  text: string
  positionType: string
  leg: string
}

export const floatNumValidatorRule: Rule[] = [
  { required: true, message: "Can't be blank!", trigger: ['change', 'blur'] },
  { pattern: new RegExp('^[z0-9]*\\.?[0-9]*$'), message: 'This is not a number!', trigger: ['change', 'blur'] }
]

export default Vue.extend({
  props: {
    maxHeightOrderForm: {
      type: Number,
      required: true
    }
  },

  data(): {
    formPurpose: FORM_PURPOSE
    orderConditional: boolean
    orderTrailing: boolean
    triggerType: TRIGGER_TYPES
    timeout: boolean
    params: Param
    isOrderPriceEditByItSelf: boolean
    btcData: number
    ethData: number
  } {
    return {
      isOrderPriceEditByItSelf: false,
      formPurpose: FORM_PURPOSE.LIMIT,
      orderConditional: false,
      orderTrailing: true,
      timeout: true,
      triggerType: TRIGGER_TYPES.RATE,
      params: {
        conditionalType: 1,
        triggerPrice: 0,
        unitPrice: 0,
        totalPrice: 0,
        orderPrice: 0,
        bookRate: 0,
        triggerTypeRate: 1,
        triggerTypePrice: 0,
        timeout: 30,
        requirements: [],
        text: '',
        positionType: '',
        leg: ''
      },
      btcData: 0,
      ethData: 0
    }
  },

  components: {
    CrossMarginFeatures,
    StepInput
  },

  computed: {
    ...mapState('exchange', ['exchange', 'pair', 'market']),

    // tradias and tradiasTestnet
    isHideLimitTab(): boolean {
      return !['tradias', 'tradiasTestnet'].includes(this.exchange)
    },

    rules(): Record<string, Rule[]> {
      return {
        // unitPrice: floatNumValidatorRule,
        // totalPrice: floatNumValidatorRule,
        timeout: this.orderConditional && this.timeout ? floatNumValidatorRule : [],
        triggerPrice: this.orderConditional ? floatNumValidatorRule : [],
        orderPrice: floatNumValidatorRule,
        triggerTypePrice:
          this.orderConditional &&
          this.isMarketPurpose &&
          this.orderTrailing &&
          this.triggerType === this.TRIGGER_TYPES.PRICE
            ? floatNumValidatorRule
            : [],
        triggerTypeRate:
          this.orderConditional &&
          this.isMarketPurpose &&
          this.orderTrailing &&
          this.triggerType === this.TRIGGER_TYPES.RATE
            ? floatNumValidatorRule
            : []
      }
    },

    FORM_PURPOSE() {
      return FORM_PURPOSE
    },

    isMarketPurpose(): boolean {
      return this.formPurpose === FORM_PURPOSE.MARKET
    },

    isLimitPurpose(): boolean {
      return this.formPurpose === FORM_PURPOSE.LIMIT
    },

    TRIGGER_TYPES() {
      return TRIGGER_TYPES
    },

    CONDITIONAL_OPTIONS() {
      return CONDITIONAL_OPTIONS
    },

    ORDER_REQUIREMENTS() {
      return ORDER_REQUIREMENTS
    },

    bookRate(): number {
      return this.params.bookRate
    },

    orderPrice(): number {
      return this.params.orderPrice
    },

    unitPrice(): number {
      return this.params.unitPrice
    },

    unitStep(): number | undefined {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!this.pair) {
        return Number(this.pair?.lotSize)
      }

      return undefined
    },

    minOrderSize(): number {
      return Number(this.pair?.minOrderSize || 0)
    },

    displayedUnitString(): string {
      if (this.pair) {
        if (this.isBitmexExchange) {
          if (this.bitmexLinearFalseQuanto) {
            return this.pair.baseCurrency
          } else if (this.bitmexInverseFalseQuanto) {
            return this.pair.quoteCurrency
          } else if (this.bitmexQuantoOnly) {
            return 'Cont'
          }
          // See: https://klaride.atlassian.net/browse/CMM-389?focusedCommentId=13386
        } else if (this.isDeribitExchange) {
          return this.pair.quoteCurrency
          // See: https://klaride.atlassian.net/browse/CMM-390
        } else if (this.isBinanceFutureExchange) {
          return 'Cont'
        } else {
          return this.pair.baseCurrency
        }
      }

      return ''
    },

    displayedTotalString(): string {
      if (this.pair) {
        if (this.isBitmexExchange) {
          if (this.bitmexLinearFalseQuanto) {
            return this.pair.quoteCurrency
          } else if (this.bitmexInverseFalseQuanto) {
            return this.pair.baseCurrency
          } else if (this.bitmexQuantoOnly) {
            if (this.pair.settlementCurrency === 'XBt') {
              return 'USD'
            }
            return 'USDT'
          }
        } else if (this.isDeribitExchange) {
          return this.pair.baseCurrency
        } else {
          return this.pair.quoteCurrency
        }
      }

      return ''
    },

    amount(): number {
      return this.unitPrice
    },

    totalPrice(): number {
      if (this.bitmexInverseFalseQuanto) {
        return this.orderPrice === 0 || this.unitPrice === 0 ? 0 : this.unitPrice / this.orderPrice
      } else if (this.bitmexQuantoOnly) {
        if (this.isXBTSettlement) {
          return this.unitPrice * this.orderPrice * this.bitmexMultiplier * this.btcData
        }
        return this.unitPrice * this.orderPrice * this.bitmexMultiplier
      } else if (this.isBinanceFutureExchange) {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!this.pair?.contractMultiplier) {
          return this.pair?.contractMultiplier * this.unitPrice
        }
        return this.orderPrice * this.unitPrice
      } else {
        return this.orderPrice * this.unitPrice
      }
    },

    // See: https://klaride.atlassian.net/browse/CMM-389
    isBitmexExchange(): boolean {
      return this.exchange.toUpperCase().includes('BITMEX')
    },

    bitmexLinearFalseQuanto(): boolean {
      return (
        !!this.pair &&
        this.isBitmexExchange &&
        this.pair.isQuanto.toUpperCase() === 'FALSE' &&
        this.pair.linearInverse === 'linear'
      )
    },

    bitmexInverseFalseQuanto(): boolean {
      return (
        !!this.pair &&
        this.isBitmexExchange &&
        this.pair.isQuanto.toUpperCase() === 'FALSE' &&
        this.pair.linearInverse === 'inverse'
      )
    },

    bitmexQuantoOnly(): boolean {
      return !!this.pair && this.isBitmexExchange && this.pair.isQuanto.toUpperCase() === 'TRUE'
    },

    bitmexMultiplier(): number {
      if (!!this.pair && this.isBitmexExchange) {
        if (this.pair.settlementCurrency === 'XBt') {
          return this.pair.contractMultiplier * 10 ** -8
        } else if (this.pair.settlementCurrency === 'USDt') {
          return this.pair.contractMultiplier * 10 ** -6
        }
      }

      return 1
    },

    isXBTSettlement(): boolean {
      return !!this.pair && this.pair.settlementCurrency === 'XBt'
    },

    orderEnable(): boolean {
      if (this.pair) {
        if (this.isBitmexExchange) {
          if (this.bitmexLinearFalseQuanto) {
            return this.params.unitPrice >= Number(this.pair.minOrderSize)
          } else if (this.bitmexInverseFalseQuanto) {
            return (
              this.params.unitPrice >= Number(this.pair.minOrderSize) &&
              this.params.unitPrice % Number(this.pair.lotSize) === 0
            )
          } else {
            return this.params.unitPrice >= 1 && this.params.unitPrice % Number(this.pair.lotSize) === 0
          }
        } else {
          if (isNaN(Number(this.pair.minOrderSize))) return true
          return this.params.unitPrice >= Number(this.pair.minOrderSize)
        }
      }
      return true
    },

    // See: https://klaride.atlassian.net/browse/CMM-389?focusedCommentId=13386
    isDeribitExchange(): boolean {
      return this.exchange.toUpperCase().includes('DERIBIT')
    },

    // See: https://klaride.atlassian.net/browse/CMM-390
    isBinanceFutureExchange(): boolean {
      return (
        this.exchange.toUpperCase().includes('BINANCEFUTURE') &&
        this.pair &&
        this.pair.contractMultiplier !== '' &&
        !isNaN(Number(this.pair.contractMultiplier))
      )
    },

    showCrossMargin(): boolean {
      return this.exchange.toUpperCase() === 'BINANCEMARGINCROSS'
    },

    // See: https://klaride.atlassian.net/browse/CMM-397?focusedCommentId=13408
    positionTypeOptions(): SelectOption[] {
      return [
        {
          label: '-----------------',
          value: ''
        },
        {
          label: 'spot',
          value: 'spot'
        },
        {
          label: 'margin',
          value: 'margin'
        }
      ]
    },

    legOptions(): SelectOption[] {
      return [
        {
          label: '-----------------',
          value: ''
        },
        {
          label: 'quote',
          value: 'quote'
        },
        {
          label: 'hedge',
          value: 'hedge'
        }
      ]
    }
  },

  methods: {
    handleChangeOrderPrice() {
      this.isOrderPriceEditByItSelf = true
    },

    connectSocket() {
      this.$socket.open()

      this.$socket.on('REALTIME_ORDERBOOK', (data: DerivativeInfoType) => {
        if (data.exchange === 'binance') {
          if (data.symbol === 'BTCUSDT') {
            this.getStatData(data)
          }

          if (data.symbol === 'ETHUSDT') {
            this.getStatData(data, 'eth')
          }
        }

        if (!this.btcData || !this.ethData) {
          if (data.exchange === 'kraken') {
            if (data.symbol === 'XXBTZUSD') {
              this.getStatData(data)
            }

            if (data.symbol === 'XETHZUSD') {
              this.getStatData(data, 'eth')
            }
          }
        }
      })
    },

    // See: https://klaride.atlassian.net/browse/CMM-389
    getStatData(records: DerivativeInfoType, symbol = 'btc') {
      const dataRes = records.asks.map((item: any) => item[0]).sort((a: number, b: number) => a - b)[0]
      if (symbol === 'btc') {
        this.btcData = dataRes
      } else {
        this.ethData = dataRes
      }
    },

    submitForm(side: SIDE_ORDER) {
      if (!this.pair) return
      const formRef = this.$refs.orderForm as FormRef

      formRef.validate((valid: boolean) => {
        if (valid) {
          const dataEmit: DataEmitOrder = {
            exchange: this.exchange,
            price: this.totalPrice,
            amount: side === 'BUY' ? this.amount : 0 - this.amount,
            symbol: `${this.pair?.symbol}`,
            baseCurrency: this.pair?.baseCurrency,
            market: this.market,
            frontend: 'ui:web',
            orderPrice: this.orderPrice,
            isMarketPurpose: this.isMarketPurpose,
            text: this.params.text,
            positionType: this.params.positionType,
            leg: this.params.leg
          }

          this.$emit('on-order-exchange', dataEmit)
        } else {
          return false
        }
      })
    }
  },

  watch: {
    bookRate() {
      this.params.unitPrice = 0
      this.params.totalPrice = 0
    },

    pair: {
      handler() {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!this.pair) {
          this.params = {
            ...this.params,
            orderPrice: 0,
            unitPrice: 0
          }
        }
      },
      deep: true,
      immediate: true
    }
  },

  created() {
    this.connectSocket()
  }
})
