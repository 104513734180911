











import Vue from 'vue'

export default Vue.extend({
  name: 'StepInput',

  props: {
    value: {
      type: Number,
      required: true
    },

    minimumValue: {
      type: Number,
      default: 0
    },

    step: {
      type: Number,
      required: false
    },

    className: {
      type: String,
      default: ''
    }
  },

  data(): {
    inputNum: number
  } {
    return {
      inputNum: 0
    }
  },

  methods: {
    go(val: number) {
      if (val === 1) {
        // See: https://klaride.atlassian.net/browse/CMM-411?focusedCommentId=13410
        if (this.minimumValue !== 0 && this.value === 0) {
          this.inputNum = this.minimumValue
        } else {
          this.inputNum = Number(this.inputNum) + this.step
        }
      } else {
        if (this.inputNum > 0) {
          // See: https://klaride.atlassian.net/browse/CMM-411?focusedCommentId=13410
          if (this.inputNum === this.minimumValue) {
            this.inputNum = 0
          } else {
            this.inputNum = Number(this.inputNum) - this.step
          }
        }
      }
    }
  },

  watch: {
    inputNum() {
      this.$emit('input', Number(this.inputNum))
    },

    value: {
      handler() {
        this.inputNum = this.value
      },
      immediate: true
    }
  }
})
